import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WrenchIcon, ClockIcon, BuildingOfficeIcon } from '@heroicons/react/20/solid';

const features = [
  {
    name: 'Convenient Booking with One Call',
    description: 'Our expert technicians are just a phone call away, providing fast service throughout the city. With quick access to your location, we make appliance repairs easy and hassle-free.',
    icon: ClockIcon,
  },
  {
    name: 'Expert Technicians for Any Repair',
    description: 'Our skilled team handles everything from simple fixes to complex issues, ensuring your appliances work flawlessly. We replace faulty parts, diagnose problems, and perform maintenance to extend their lifespan.',
    icon: WrenchIcon,
  },
  {
    name: 'Fast and Reliable Repairs in Houston and Nearby Areas',
    description: 'Our expert technicians provide quick, dependable appliance repair services, ensuring your home runs smoothly no matter where you are in Houston and the surrounding areas.',
    icon: BuildingOfficeIcon,
  },
]

export default function Landing() {

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/reviews');
  };

  return (
    <div data-aos="fade-left">
      <div className="overflow-hidden bg-white py-12 sm:py-36">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">Your Trusted Solution for All Appliance Woes</h2>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>

          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-dark sm:text-4xl">
              Vector Appliance Repair
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="#"
                onClick={handleNavigation}
                className="text-sm font-semibold leading-6 text-indigo-600 cursor-pointer"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Is a <span className='text-indigo-500'>faulty appliance disrupting your day?</span> Let our expert technicians restore your routine with fast, reliable repairs.
            </p>
          </div>

          </div>
        </div>
      </div>
    </div>

  )
}
