import React from 'react';
import { FaStar } from "react-icons/fa6";

export default function WarehouseImages() {

  const testimonials = [
    {
      quote: "I called Vector Appliance Repair on a Saturday for my gas range issues, and I couldn't be happier with the service. Vladimir was prompt, knowledgeable, and fixed the problem in no time. I also had him check my fridge, which was acting up, and he resolved that too! Highly recommended!",
      author: "Sarah T.",
      rating: 5,
    },
    {
      quote: "My dryer had been making strange noises, and I was worried it might be broken for good. I contacted Vector Appliance Repair and spoke with Vladimir. He arrived quickly and diagnosed the issue in no time. Not only did he fix the dryer, but he also checked my washer for any potential problems. Excellent service!",
      author: "Tom R.",
      rating: 5,
    },
    {
      quote: "I had a frustrating week with my gas range, fridge, and washer all giving me trouble. Thankfully, I found Vector Appliance Repair! Vladimir came to my house and worked wonders on all my appliances. He's a true professional and very friendly. I’ll definitely call him again for any future repairs!",
      author: "Linda H.",
      rating: 4,
    },
    {
      quote: "Vladimir from Vector Appliance Repair was a lifesaver! My gas range was acting up, and my fridge was warm. He answered my call right away and showed up the same day. He fixed everything efficiently and gave me some great maintenance tips. I can't recommend him enough!",
      author: "Jason L.",
      rating: 4,
    },
    {
      quote: "I was having multiple issues with my appliances, from the fridge not cooling to the dryer not spinning. I found Vector Appliance Repair through a quick search and decided to give them a try. Vladimir arrived promptly and handled everything like a pro. Super satisfied with the results!",
      author: "Emily D.",
      rating: 5,
    },
    {
      quote: "Vladimir with Vector Appliance Repair is fantastic! He was called out for my gas range and ended up fixing my fridge and washer too. He explained everything he was doing and made me feel at ease. I appreciate the honesty and efficiency!",
      author: "Kyle W.",
      rating: 4,
    },
    {
      quote: "Vector Appliance Repair saved my day! My dryer wouldn’t start, and my fridge was leaking. I called Vladimir on his cell, and he arrived within the hour. He not only fixed the dryer but also identified the fridge issue quickly. Great service!",
      author: "Nina P.",
      rating: 4,
    },
    {
      quote: "I had an emergency with my gas range and called Vector Appliance Repair after a friend’s recommendation. Vladimir answered right away and came to my home in Houston. He was very professional and fixed my range, fridge, and even checked my washer! I’m thrilled with the service!",
      author: "Kevin J.",
      rating: 5,
    },
  ];  

  const StarRating = ({ rating }) => (
    <div className="flex text-indigo-500 mb-4">
      {[...Array(5)].map((_, i) => (
        <FaStar key={i} fill={i < rating ? "currentColor" : "none"} />
      ))}
    </div>
  );
  
  const TestimonialWithStars = ({ quote, author, rating }) => (
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto mb-8" data-aos="fade-up">
      <StarRating rating={rating} />
      <blockquote className="text-xl text-gray-700 mb-6">
        "{quote}"
      </blockquote>
      <div className="flex items-center">
        <div>
          <p className="font-semibold text-gray-900">{author}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h1 className="text-2xl py-5 text-center font-bold text-gray-900">Reviews</h1>
          <hr />
          <div className="py-5">
            {testimonials.map((testimonial, index) => (
              <TestimonialWithStars 
                key={index} 
                quote={testimonial.quote} 
                author={testimonial.author} 
                rating={testimonial.rating} 
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
