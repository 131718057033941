import React, { useState } from 'react';
import ImageModal from './ImageModal';
import VectorApplianceImageOne from '../assets/Vector_Appliance_Repair_Icon_Gas_Range_Before_1.png';
import VectorApplianceImageTwo from '../assets/Vector_Appliance_Repair_Icon_Gas_Range_Before_2.png';
import VectorApplianceImageThree from '../assets/Vector_Appliance_Repair_Icon_Gas_Range_After_1.png';


const callouts = [
  {
    name: 'Before the Replacement',
    description: 'An image of a gas burner with a broken glass top.',
    imageSrc: VectorApplianceImageOne,
    imageAlt: 'A gas burner with a shattered glass top, showing visible cracks and damage before replacement.',
  },
  {
    name: 'Detailed Image of the Gas Range',
    description: 'A much closer look of the broken gas range.',
    imageSrc: VectorApplianceImageTwo,
    imageAlt: 'A close-up view of a broken gas range, highlighting the damaged components and cracks on the surface.',
  },
  {
    name: 'Completely Fixed Gas Range',
    description: 'Completely fixed gas range ready to use.',
    imageSrc: VectorApplianceImageThree,
    imageAlt: 'A perfectly cleaned appliance, showcasing the pristine condition and completion of the job, reflecting a successful cleaning and fulfillment.',
  }
];

export default function ShowcaseOne() {
  const [ selectedImage, setSelectedImage ] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div data-aos="fade-down">
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-8">
            <h1 className="text-2xl py-5 text-center font-bold text-gray-900">Gas Range Repair</h1>
            <hr />
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative h-96 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-3 sm:aspect-w-2 lg:aspect-h-4 lg:aspect-w-2 group-hover:opacity-75 sm:h-80">
                  <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      onClick={() => openModal(callout.imageSrc)}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    {callout.name}
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {selectedImage && (
          <ImageModal 
            isOpen={!!selectedImage}
            onClose={closeModal}
            imgSrc={selectedImage}
            width={2432}
            height={1442}
            alt={selectedImage}
          />
        )}
      </div>
    </div>

  );
}