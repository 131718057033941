import ShowcaseTwo from '../components/ShowcaseTwo';
import Navbar from '../components/Header';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
    <>
      <Helmet>
        <title>Gas Range Repair</title>
        <meta name="description" content="Gas Range Repair." />
      </Helmet>

      <Navbar />
      <ShowcaseTwo />
    </>
  );
}