import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const destinationAddress = "Houston";

const openGoogleDirections = () => {
  const destination = encodeURIComponent(destinationAddress);
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  window.open(googleMapsUrl, '_blank');
};



const subCategories = [
    { name: 'Apple Maps', url: 'https://maps.apple.com/?address=Houston,%20TX,%20United%20States&auid=5265977367204441420&ll=29.760376,-95.369805&lsp=6489&q=Houston' },
    { name: 'Bing Maps', url: 'https://www.bing.com/maps?&cp=29.816906~-95.763922&lvl=10.20754&pi=0&osid=72cf2db1-3575-4c84-ac51-115c5ff43106&v=2&sV=2&form=S00027' },
    { name: 'Google Earth', url: 'https://earth.google.com/web/search/Houston,+TX,+USA/@29.81717795,-95.40129145,21.96523334a,129084.74024143d,35y,0.00000001h,0.0004375t,0r/data=Cn8aURJLCiUweDg2NDBiOGI0NDg4ZDg1MDE6MHhjYTBkMDJkZWYzNjUwNTNiGUPwrmmUwj1AIV55NeWv11fAKhBIb3VzdG9uLCBUWCwgVVNBGAIgASImCiQJuL7R9h7fHEARp61U333NG0AZsrNEuSN5X0AhK6v4b2pdX0BCAggBOgMKATBKDQj___________8BEAA' },
    { name: 'Google Maps', url: 'https://www.google.com/maps/dir//Houston,+TX,+USA/@29.8167689,-95.7309312,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640b8b4488d8501:0xca0d02def365053b!2m2!1d-95.3701108!2d29.7600771?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D' },
  ];

export default function Maps() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  return (
    <div className="bg-white" data-aos="fade-up">
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
          {/* ... Transition and Dialog components ... */}
          <div className="fixed inset-0 z-40 flex">
            {/* ... Transition Child components ... */}
            <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <form className="mt-4 border-t border-gray-200">
                <ul className="px-2 py-3 font-medium text-gray-900">
                  {subCategories.map((category) => (
                    <li key={category.name}>
                      <a href={category.url} className="block px-2 py-3 text-left w-full" target="_blank" rel="noopener noreferrer">{category.name}</a>
                    </li>
                  ))}
                  <li>
                    <button onClick={openGoogleDirections} className="block px-2 py-3 text-left w-full">Google Directions</button>
                  </li>
                </ul>
              </form>

            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Main Content */}
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 pb-6 pt-24 border-b border-gray-200">Our Location</h1>

        <section aria-labelledby="products-heading" className="pb-24 pt-6">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
            {/* Desktop Filters */}
            <form className="hidden lg:block">
              <ul className="px-2 py-3 font-medium text-gray-900">
                {subCategories.map((category) => (
                  <li key={category.name}>
                    <a href={category.url} className="block px-2 py-3 text-left w-full" target="_blank" rel="noopener noreferrer">{category.name}</a>
                  </li>
                ))}
                <li>
                  <button onClick={openGoogleDirections} className="block px-2 py-3 text-left w-full">Google Directions</button>
                </li>
              </ul>
            </form>

            <div className="lg:col-span-3">
                 <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d886192.2699242007!2d-96.06072415541715!3d29.815540834484793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640b8b4488d8501%3A0xca0d02def365053b!2sHouston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sph!4v1728882995379!5m2!1sen!2sph"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>

          </div>
        </section>
      </main>
    </div>
  );
}
