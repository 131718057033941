import Landing from '../components/Landing';
import Navbar from '../components/Header';
import ShowcaseWarehouseOne from '../components/ShowcaseOne';
import ShowcaseWarehouseTwo from '../components/ShowcaseTwo';
import ShowcaseWarehouseThree from '../components/ShowcaseThree';
import WarehouseImages from '../components/Reviews.js';
import Map from '../components/Map';
import Form from '../components/Form';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Vector Appliance Repair" />
      </Helmet>
      <Navbar />
      <Landing />
      <ShowcaseWarehouseOne />
      <ShowcaseWarehouseTwo />
      <ShowcaseWarehouseThree />
      <Map />
      <WarehouseImages />
      <Form />

    </>
  );
}