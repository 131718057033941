import React, { useState } from 'react';
import ImageModal from './ImageModal';
import VectorApplianceImageOne from '../assets/Vector_Appliance_Repair_Icon_Cleaning_Before_1.png';
import VectorApplianceImageTwo from '../assets/Vector_Appliance_Repair_Icon_Cleaning_After_1.png';
import VectorApplianceImageThree from '../assets/Vector_Appliance_Repair_Icon_Cleaning_Before_2.png';
import VectorApplianceImageFour from '../assets/Vector_Appliance_Repair_Icon_Cleaning_After_2.png';

const callouts = [
  {
    name: 'Before Cleaning the Washing Machine',
    description: 'An image of a dirty washing machine, filled with dirt and ashes.',
    imageSrc: VectorApplianceImageOne,
    imageAlt: 'A dirty washing machine filled with grime and ashes, showcasing its condition before cleaning.',
  },
  {
    name: 'Detailed Image of the end result of the intensive cleaning',
    description: 'A much closer look post-cleaning washing machine.',
    imageSrc: VectorApplianceImageTwo,
    imageAlt: 'A close-up view of the washing machine after intensive cleaning, highlighting its spotless interior and pristine condition.',
  },
  {
    name: 'Before the cleaing of the refrigerator defrost',
    description: 'An image of pre-defrost heater.',
    imageSrc: VectorApplianceImageThree,
    imageAlt: 'An image of the pre-defrost heater inside a refrigerator, showing visible frost buildup before the defrosting process.',
  },
  {
    name: 'Completely Cleaned Refrigerator',
    description: 'Completely fixed gas range ready to use.',
    imageSrc: VectorApplianceImageFour,
    imageAlt: 'A fully cleaned refrigerator, showcasing a spotless interior with neatly organized shelves, ready for use.',
  }
];

export default function ShowcaseOne() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div data-aos="fade-down">
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-8">
            <h1 className="text-2xl py-5 text-center font-bold text-gray-900">Appliance Cleaning</h1>
            <hr />
            <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      onClick={() => openModal(callout.imageSrc)}
                      className="h-full w-full object-cover object-center cursor-pointer"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    {callout.name}
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {selectedImage && (
          <ImageModal
            isOpen={!!selectedImage}
            onClose={closeModal}
            imgSrc={selectedImage}
            width={2432}
            height={1442}
            alt={selectedImage}
          />
        )}
      </div>
    </div>
  );
}