import ShowcaseThree from '../components/ShowcaseThree';
import Navbar from '../components/Header';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
    <>
      <Helmet>
        <title>Appliance Cleaning</title>
        <meta name="description" content="Appliance Cleaning" />
      </Helmet>

      <Navbar />
      <ShowcaseThree />
    </>
  );
}