export default function ContactUs() {
    return (
      <div data-aos="fade-up">
        <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
          <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <div className="flex flex-col items-center py-10">
              <h2 className="text-3xl font-bold tracking-tight text-dark sm:text-4xl">
                Vector Appliance Repair
              </h2>
              <p className="mt-2 text-base text-gray-500 italic">
                Your Trusted Solution for All Appliance Woes!
              </p>
            </div>

            <figure className="mt-10">
              <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <h3 className="text-lg font-semibold text-gray-900">
                  Ready to Work With Us?
                </h3>
                <p className="mt-2 text-base text-gray-500">
                  Got an issue with your appliances? Give us a call, and we’ll fix it for you!
                </p>
              </blockquote>
              <figcaption className="mt-10">
                <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                  <div className="font-semibold text-gray-900">Vladimir Gasimov</div>
                  <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div className="text-gray-600">General Manager</div>
                  {/* <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div className="text-indigo-600">
                  <a target="_blank" rel="noopener noreferrer" className="text-indigo-600">
                    <FaFacebook />
                  </a>
                  </div> */}

                </div>
                <div className="mt-8 flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-3 text-base">
                  <a href="tel:+18329139415" className="text-rose-400 font-bold">
                    +1 (832) 913-9415
                  </a>
                </div>


              </figcaption>
            </figure>
          </div>
        </section>
      </div>
    )
  }
  