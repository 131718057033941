import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './page/Home';
import Contact from './page/Contact';
import Location from './page/Location';
import Features from './page/Features';
import WasherRepair from './page/RepairOne';
import GasRepair from './page/RepairTwo';
import ApplianceCleaning from './page/RepairThree';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Home />} />
        <Route path="/location" element={<Location />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/reviews" element={<Features />} />

        <Route path="/washer-dryer-repair" element={<WasherRepair />} />
        <Route path="/gas-range-repair" element={<GasRepair />} />
        <Route path="/appliance-cleaning" element={<ApplianceCleaning />} />

      </Routes>
    </Router>
  );
}
